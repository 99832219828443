<template>
  <div class="page page-Competition" :class="{ loaded: isLoaded }">
    <div class="centering">
      <div>
        <h1 class="comp-title">{{ $t('lang.competition.title') }}</h1>
        <p class="comp-details">{{ $t('lang.competition.details') }}</p>
        <div class="social social-block">
          <a
            href="https://vk.com/delimobil"
            target="_blank"
            rel="noopener"
            aria-label="link to vk"
            class="social-icon social-vk"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';

export default {
  name: 'Competition',
  mixins: [loader, isMobile, functions],

  data() {
    return {
      pageData: {
        page: 'Competition',
        isFooterHidden: true,
        isMobileFooterHidden: true,
        isHeaderHidden: true,
        isHamburgerHidden: true,
      },
    };
  },

  deactivated() {
    this.isLoaded = false;
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },

  methods: {
    onActivated() {
      this.pageLoaded();
    },
  },
};
</script>

<style>
.page-Competition {
  background: #28e1b9;
}
.centering {
  margin: 0 30px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comp-title {
  font-size: 2vw;
}

.comp-details {
  font-size: 1.3vw;
}

.social-block {
  margin-top: 2vw;
}

.social-icon {
  height: 4vw;
  width: 4vw;
  background: no-repeat center / contain;
  margin-right: 30px;
}

.social-insta {
  background-image: url(../assets/images/insta.svg);
}

.social-insta:hover {
  background-image: url(../assets/images/insta-white.svg);
}

.social-facebook {
  background-image: url(../assets/images/facebook.svg);
}

.social-facebook:hover {
  background-image: url(../assets/images/facebook-white.svg);
}

.social-vk {
  background-image: url(../assets/images/vk.svg);
}

.social-vk:hover {
  background-image: url(../assets/images/vk-white.svg);
}

@media screen and (max-width: 767px) {
  .comp-title {
    font-size: 8vw;
  }

  .comp-details {
    margin-top: 20px;
    font-size: 5vw;
  }

  .social-icon {
    height: 10vw;
    width: 10vw;
  }

  .social-block {
    margin-top: 7vw;
  }
}
</style>
